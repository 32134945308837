<template>
  <div class="index">
    <div class="nav" ref="nav" :class="banner_bg ? 'nav_active_bg' : ''">
      <div class="nav_logo index_content">
        <img src="../assets/imgs/logo.png" alt="" />
        <!-- </div> -->
        <div class="nav_title">
          <div
            v-for="item in nav_title"
            :key="item.index"
            :class="[nav_is_index == item.id ? 'is_dom_nav_active' : '']"
          >
            <span
              :class="[nav_is_index == item.id ? 'is_nav_active' : '']"
              @click="goDom(item.dom, item.id, $event)"
              @mousemove="goBomMousemOve(item.id, $event)"
              @mouseout="goBomMousemOut($event)"
              :data-title="item.title"
              >{{ item.title }}</span
            >
          </div>
        </div>
      </div>
    </div>
    <div class="banner" ref="banner">
      <el-carousel trigger="click" height="560px" arrow="never">
        <el-carousel-item
          v-for="item in banner_arr"
          :key="item.index"
          :class="'banner_' + item.id"
        >
          <div class="index_content">
            <div class="bannet_item_left">
              <div>
                <span>{{ item.title }}</span
                >{{ item.title_cype }}
              </div>
              <div></div>
              <div>{{ item.title_content }}</div>
              <div v-if="item.id == 0 ? false : true">
                {{ item.button }}
                <div class="banner_button_mode"></div>
              </div>
              <div v-if="item.id == 0 ? false : true">{{ item.describe }}</div>
            </div>
            <div class="bannet_item_right"></div>
            <!-- <h3>{{ item }}</h3> -->
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div id="scene">
      <div class="scene_banner">
        <div class="index_content">
          <div>应用场景</div>
          <div>满足各阶段人群不同需求、让生活变得简单</div>
          <div>
            <div
              v-for="item in scene_arr"
              :key="item.index"
              class="scene_banner_conent"
              @mouseover="mouseOver(item, $event)"
              :class="scene_title_ictive == item.id ? 'scene_banner_conent_active' : ''"
              :style="{
                transition: '0.5s all',
              }"
            >
              <div class="scene_banner_item">
                <!-- <div>{{ item.title_cype }}</div> -->
                <span
                  class="span_title_cype"
                  v-if="scene_title_ictive == item.id ? false : true"
                  >{{ item.title_cype }}</span
                >
                <span class="span_width" v-else>{{ item.title }}</span>
              </div>
              <img :src="item.img" alt="" />
              <div
                :style="{
                  background: item.bg,
                }"
                class="scene_banner_item_bg"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div class="scene_advantage">
        <div class="index_content">
          <div>为什么选择享影云电脑？</div>
          <div>随时可用、高配体验</div>
          <div>
            <div class="scene_advantage_item">
              <div>
                <div>
                  <img src="../assets/imgs/scene_1.png" alt="" />
                </div>
                <div>减成本</div>
              </div>
              <div>
                <div></div>
                <div>无需另购电脑，低价使用享影云电脑，满足日常所需</div>
              </div>
            </div>
            <div class="scene_advantage_item">
              <div>
                <div><img src="../assets/imgs/scene_2.png" alt="" /></div>
                <div>省时间</div>
              </div>
              <div>
                <div></div>
                <div>丰富的软件库，不用等待下载安装，拉起即用，时间利用更高效</div>
              </div>
            </div>
            <div class="scene_advantage_item">
              <div>
                <div><img src="../assets/imgs/scene_3.png" alt="" /></div>
                <div>高配置</div>
              </div>
              <div>
                <div></div>
                <div>高配置的享影云电脑，享受PC大型游戏极致体验</div>
              </div>
            </div>
            <div class="scene_advantage_item">
              <div>
                <div><img src="../assets/imgs/scene_4.png" alt="" /></div>
                <div>低能耗</div>
              </div>
              <div>
                <div></div>
                <div>降低终端耗电量，创建绿色节能新生活</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="introduce">
      <div class="introduce_constitute">
        <div class="index_content">
          <div>产品构成</div>
          <div>极致、便捷、服务</div>
          <div class="introduce_constitute_item">
            <div
              class="introduce_constiute_time_right"
              :style="{ background: options_background }"
            >
              <div class="introduce_constiute_time_right_content">
                <div :style="{ background: options_background }">
                  <div class="title_item">
                    <div
                      v-for="(item, index) in introduce_arr"
                      :key="item.index"
                      :class="index == 0 ? 'title_item_active' : ''"
                    >
                      <div class="introduce_form_title_cype">
                        <span>{{ item.title_cype }}</span>
                      </div>
                      <div></div>
                      <div class="introduce_form_content">
                        {{ item.content }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="options">
                  <div
                    class=""
                    v-for="(item, index) in introduce_arr"
                    :key="item.index"
                    :class="index == 0 ? 'options_active' : ''"
                    @click="introduceArrLeft(index)"
                  >
                    <img :src="item.img" alt="" />
                    <div :style="{ background: item.bg }"></div>
                    <div>{{ item.title }}</div>
                  </div>
                </div>
              </div>
              <div class="introduce_constiute_time_left_top">
                <div class="introduce_constiute_time_left_top_img">
                  <img
                    class="introduce_constitute_active"
                    src="../assets/imgs/introduce_1.png"
                  />
                  <img src="../assets/imgs/introduce_2.png" />
                  <img src="../assets/imgs/introduce_3.png" />
                </div>
                <div class="introduce_constiute_time_left_top_button">
                  <div
                    class="button_left button_left_test"
                    @click="introduceArrLeft('left')"
                  >
                    <i class="iconfont icon-lishifanyeim"></i>
                  </div>

                  <div
                    class="button_right button_right_test"
                    @click="introduceArrLeft('right')"
                  >
                    <i class="iconfont icon-lishifanyeim-copy"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="download">
      <div class="download_bg">
        <div class="index_content">
          <div>客户端下载</div>
          <div>立即下载、即可免费体验云电脑</div>
          <div>
            <div class="download_bg_item download_bg_item_active">
              <div>
                <div>
                  <!-- <img src="../assets/imgs/tv.png" alt="" /> -->
                  <i class="iconfont icon-tv"></i>
                </div>
                <div>TV端</div>
                <div>下载</div>
              </div>
              <div class="download_bg_item_title">
                <span>下载后通过 U盘 安装至电视</span>
              </div>
              <div class="download_mode"></div>
            </div>
            <div class="download_bg_item">
              <div>
                <div>
                  <i class="iconfont icon-windows"></i>
                  <!-- <img src="../assets/imgs/win.png" alt="" /> -->
                </div>
                <div>Windows</div>
                <div>敬请期待</div>
              </div>
              <div class="download_bg_item_title">
                <span>下载后通过 U盘 安装至电视</span>
              </div>
            </div>
            <div class="download_bg_item">
              <div>
                <div><i class="iconfont icon-anzhuo"></i></div>
                <div>Android</div>
                <div>敬请期待</div>
              </div>
              <div class="download_bg_item_title">
                <span>下载后通过 U盘 安装至电视</span>
              </div>
            </div>
            <div class="download_bg_item">
              <div>
                <div><i class="iconfont icon-iOS"></i></div>
                <div>iOS</div>
                <div>敬请期待</div>
              </div>
              <div class="download_bg_item_title">
                <span>下载后通过 U盘 安装至电视</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="download_step">
        <div class="index_content">
          <div>如何使用享影云电脑</div>
          <div>轻松三步</div>
          <div>
            <div>
              <div>下载并安装客户端</div>
              <div>01</div>
              <div></div>
            </div>
            <img src="../assets/imgs/right.png" alt="" />
            <div>
              <div>免费体验/购买时长</div>
              <div>02</div>
              <div></div>
            </div>
            <img src="../assets/imgs/right.png" alt="" />
            <div>
              <div>运行云电脑，立即畅玩</div>
              <div>03</div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="about">
      <div class="about_advantage">
        <div class="index_content">
          <div>我们的优势</div>
          <div>
            <div>
              <div><img src="../assets/imgs/about_1.png" alt="" /></div>
              <div>专业的产品技术</div>
              <div>
                <div>具备领先的自主研发实力和技术优势</div>
              </div>
              <div class="img_bg"></div>
            </div>
            <div>
              <div><img src="../assets/imgs/about_2.png" alt="" /></div>
              <div>资源有效管理</div>
              <div>主机资源配置效益最大化</div>
              <div class="img_bg"></div>
            </div>
            <div>
              <div><img src="../assets/imgs/about_4.png" alt="" /></div>
              <div>实时获取收益</div>
              <div>实时查看收益数据，0费用提现</div>
              <div class="img_bg"></div>
            </div>
            <div>
              <div><img src="../assets/imgs/about_3.png" alt="" /></div>
              <div>方便，快捷</div>
              <div>灵活的合作模式，祝你轻松躺赢</div>
              <div class="img_bg"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="about_join">
        <div class="index_content">
          <div>如何加入我们？</div>
          <div>
            <div>
              <div>
                <img src="../assets/imgs/reg.png" alt="" />
              </div>
              注册云电脑管理账号
            </div>
            <img src="../assets/imgs/right.png" alt="" />
            <div>
              <div>
                <img src="../assets/imgs/zhi.png" alt="" />
              </div>
              咨询合作模式
            </div>
            <img src="../assets/imgs/right.png" alt="" />
            <div>
              <div><img src="../assets/imgs/zi.png" alt="" /></div>
              资质审核
            </div>
            <img src="../assets/imgs/right.png" alt="" />
            <div>
              <div><img src="../assets/imgs/he.png" alt="" /></div>
              合作达成
            </div>
          </div>
          <div class="top_dom">
            <div class="reg_button">
              立即注册
              <img src="../assets/imgs/reg_left.png" class="an_fa" alt="" />
              <div class="button_mode"></div>
            </div>
          </div>
          <div></div>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="footer_top">
        <div class="index_content">
          <div class="footer_top_left">
            <div>关于</div>
            <div class="footer_top_left_spacing">
              雾算（成都）科技有限公司，是一家专注于云网吧、云网咖、云电脑业务的科技创新企业，
              基于自主研发的虚拟化、GPU容器技术、高清图像传输协议和云无盘等核心产品与技术，
              面向网吧、酒店等细分市场打造2C、2B的游戏IaaS和SaaS解决方案，助力网吧、游戏、
              电竞等传统行业的数字化转型升级，践行数字经济、共享经济新发展理念。
            </div>
          </div>
          <div class="footer_top_content">
            <div>链接</div>
            <div>
              <span>关于合作</span>
              <span>用户协议</span>
              <span>隐私协议</span>
            </div>
            <div>下载</div>
            <div>
              <span>TV端下载</span>
            </div>
          </div>
          <div class="footer_top_right">
            <div>关注或联系我们</div>
            <div>
              <i class="iconfont icon-shejiaotubiao-03">
                <div>
                  <span>QQ群</span>
                  <img src="../assets/imgs/erweima.png" alt="" />
                </div>
              </i>
              <i class="iconfont icon-gongzhonghao">
                <div>
                  <span>微信公众号</span>
                  <img src="../assets/imgs/erweima.png" alt="" />
                </div>
              </i>
            </div>
            <div>咨询电话</div>
            <div>0472-2306 666</div>
          </div>
        </div>
      </div>
      <div class="footer_bottom">
        <div class="index_content">
          <span>©版权信息 | </span>
          <span>&nbsp;地址 | </span>
          <span>&nbsp;备案信息 | </span>
          <span
            >&nbsp;公网安备
            <img src="../assets/imgs/icon.png" alt="">
            <a
              href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=51010702002940"
              target="_blank"
              rel="noopener noreferrer"
              >51010702002940</a
            >
            &nbsp;
            <a href="https://beian.miit.gov.cn/" target="_blank"
              >蜀ICP备2022015747号-1</a
            ></span
          >
        </div>
      </div>
    </div>
    <!-- 回到顶部 -->
    <el-backtop>
      <div class="scrollTop">
        <i class="icon-top iconfont"></i>
      </div>
    </el-backtop>
  </div>
</template>

<script>
export default {
  data() {
    return {
      nav_title: [
        {
          id: 0,
          title: "应用场景",
          dom: "#scene",
        },
        {
          id: 1,
          title: "产品介绍",
          dom: "#introduce",
        },
        {
          id: 2,
          title: "下载",
          dom: "#download",
        },
        {
          id: 3,
          title: "关于合作",
          dom: "#about",
        },
      ],
      index: "",
      dom: "",
      is_active_nav: false,
      nav_is_index: -1,
      banner_bg: false,
      banner_arr: [
        {
          id: 0,
          title: "",
          title_cype: "",
          title_content: "让世界如影随行",
          button: "",
          describe: "",
        },
        {
          id: 1,
          title: "电视",
          title_cype: "秒变电脑，您的家庭电脑",
          title_content: "下载后通过U盘安装至机顶盒",
          button: "TV端下载",
          describe: "",
        },
        {
          id: 2,
          title: "行业",
          title_cype: "解决方案",
          title_content:
            "对传统市场资源有效整合，实现高性能主机云端化，充分利用闲置资源，提高企业经营效率，助力行业数字化转型",
          button: "加入我们",
          describe: "立即注册、登录管理后台",
        },
      ],
      introduce_arr: [
        {
          id: 0,
          title: "极致",
          title_cype: "极致体验",
          img: require("../assets/imgs/introduce_1.png"),
          content: "基于雾计算，利用GPU算力，保证低延迟，为用户提供极致的使用体验",
          bg: "#0D2250",
        },
        {
          id: 1,
          title: "便捷",
          title_cype: "使用便捷",
          img: require("../assets/imgs/introduce_2.png"),
          content:
            "TV，PC，PAD，手机多终端连接，无需新增其他终端，TV端支持外接鼠标，键盘",
          bg: "#033050",
        },
        {
          id: 2,
          title: "服务",
          title_cype: "优质服务",
          img: require("../assets/imgs/introduce_3.png"),
          content: "24小时运维和客服团队，为用户提供全方位技术支持和售前售后服务",
          bg: "#002C5E",
        },
      ],
      options_background: "rgb(13,34,80)",
      introduce_form: null,
      scene_arr: [
        {
          id: 0,
          title: "中小学生轻松学习",
          title_cype: "学习",
          img: require("../assets/imgs/scene_banner_1.png"),
          bg: "rgb(27, 75, 60)",
        },
        {
          id: 1,
          title: "职场人高效办公",
          title_cype: "办公",
          img: require("../assets/imgs/scene_banner_2.png"),
          bg: "rgb(0, 43, 86)",
        },
        {
          id: 2,
          title: "玩家畅享游玩",
          title_cype: "游戏",
          img: require("../assets/imgs/scene_banner_3.png"),
          bg: "rgb(71, 25, 32)",
        },
        {
          id: 3,
          title: "共享家庭娱乐",
          title_cype: "娱乐",
          img: require("../assets/imgs/scene_banner_4.png"),
          bg: "rgb(56, 50, 45)",
        },
      ],
      scene_title_ictive: 0,
      introduce_index: 0,
    };
  },
  methods: {
    // 导航栏
    goBomMousemOve(index, e) {
      e.target.classList.add("is_top_nav_active");
    },
    goBomMousemOut(e) {
      e.target.classList.remove("is_top_nav_active");
    },
    // 应用场景
    mouseOver(item, e) {
      this.scene_title_ictive = item.id;
    },
    // 产品构成
    introduceArrLeft(type) {
      this.$nextTick(() => {
        let color = document.querySelector(".options_active div:nth-child(2)");
        // console.log("color1:", color.style.background);
        // console.log(1);
        this.options_background = color.style.background;
        // console.log("===2", this.options_background);
      });
      // console.log("===3", this.options_background);
      //
      let imgs = document.querySelectorAll(
        ".introduce_constiute_time_left_top_img > img"
      );
      let options = document.querySelectorAll(".options > div");
      let title = document.querySelectorAll(".title_item > div");
      // var index = 0
      imgs[this.introduce_index].className = "";
      options[this.introduce_index].className = "";
      title[this.introduce_index].className = "";
      if (type == "left") {
        this.introduce_index--;
      } else if (type == "right") {
        this.introduce_index++;
      } else {
        this.introduce_index = type;
      }
      if (this.introduce_index >= imgs.length) {
        this.introduce_index = 0;
      }
      if (this.introduce_index < 0) {
        this.introduce_index = imgs.length - 1;
      }
      /* 
      // return;*/
      imgs[this.introduce_index].classList = "introduce_constitute_active";
      options[this.introduce_index].classList = "options_active";
      title[this.introduce_index].classList = "title_item_active";
    },
    /**滚动事件 */
    handleScroll() {
      //获取页面滚动条的高度
      // return;
      let banner_top = this.$refs.banner.offsetHeight;
      let nav_top = this.$refs.nav.offsetHeight;
      let hei = banner_top - nav_top;
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      let an_fa = document.querySelector(".an_fa");

      if (scrollTop > 3600) {
        // return
        setTimeout(() => {
          this.$nextTick(() => {
            an_fa.classList.add(
              "animate_black",
              "animate__animated",
              "animate__fadeInTopRight"
            );
          });
        }, 400);
      }
      if (scrollTop > 50) {
        this.banner_bg = true;
      } else {
        this.banner_bg = false;
      }
      if (scrollTop >= banner_top && scrollTop < 1664) {
        this.nav_is_index = 0;
      } else if (scrollTop >= 1664 && scrollTop < 2670) {
        this.nav_is_index = 1;
      } else if (scrollTop >= 2670 && scrollTop < 3676) {
        this.nav_is_index = 2;
      } else if (scrollTop >= 3676) {
        this.nav_is_index = 3;
      } else {
        this.nav_is_index = -1;
      }
    },
    //页面滚动要做的事情
    getOffsetTop(obj) {
      return;
    },

    goDom(item, index, e) {
      e.target.classList.remove("is_top_nav_active");

      this.nav_is_index = index;
      let obj = document.querySelector(item);
      let offsetTop_1 = 0;
      while (obj != window.document.body && obj != null) {
        offsetTop_1 += obj.offsetTop;
        obj = obj.offsetParent;
      }
      let nav_height = this.$refs.nav.offsetHeight;
      let scrollTop_1 = offsetTop_1 - nav_height;
      window.scrollTo({
        top: scrollTop_1,
        behavior: "smooth",
      });
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    //离开该页面需要移除这个监听的事件
    window.removeEventListener("scroll", this.handleScroll);
  },
  created() {
    this.introduce_form = this.introduce_arr[0];
    // this.options_background = this.introduce_arr[0].bg
    console.log("this.introduce_form:", this.introduce_form);
    /* this.$nextTick(()=>{
      console.log("==", document.querySelector(".options_active"));
   }) */
  },
};
</script>

<style lang="less" scoped>
@font_color: #9ca5af;
.index {
  box-sizing: border-box;
  // height: 1200px;
  background-color: #f7f9fa;
  .index_content {
    width: 1200px;
    margin: 0 auto;
    a {
      color: @font_color;
      text-decoration: none;
    }
    /* img{
      height: 16px;
    } */
  }
  .nav_content {
    width: 1200px;
    // border: 1px solid #000;
  }
  .nav {
    position: sticky;
    top: 0;
    // background-color: #051b4a;
    // display: flex;
    // justify-content: space-between;
    height: 56px;
    z-index: 1000;
    .nav_logo {
      display: flex;
      justify-content: space-between;
      align-items: center;
      // margin-left: 30px;
      // flex: 1;
      img {
        height: 56px;
      }
    }
    .nav_title {
      width: 560px;
      color: #93a0b4;
      // transition: all 0.3s ease-out 0s;
      font-size: 14px;
      height: 100%;
      position: relative;
      // float: right;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      overflow: hidden;
      > div {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 20px;
      }
      span {
        width: 100%;
        height: 100%;
        display: inline-block;
        position: relative;
        transition: all ease-out 0.3s;
        box-sizing: border-box;
        padding: 8px 0px;
        text-align: center;
        cursor: pointer;
      }

      .is_top_nav_active::before {
        height: 100%;
        width: 100%;
        content: "";
        position: absolute;
        bottom: -35px;
        left: 0;
        transform: translateY(-100%);
      }
      .is_top_nav_active::after {
        color: #fff;
        content: attr(data-title);
        position: absolute;
        left: 0;
        transform: translateY(100%);
        width: 100%;
        box-sizing: border-box;
        padding: 8px 0px;
      }
      .is_top_nav_active:hover {
        transition: all ease-out 0.3s;
        transform: translateY(-100%);
      }
      .is_top_nav_active:hover::after {
        color: #fff;
        box-sizing: border-box;
        content: attr(data-title);
        position: absolute;
        box-sizing: border-box;
        padding: 8px 0px;
        color: #e40807;
        border-radius: 18px;
        color: #fff;
        left: 0;
        transform: translateY(100%);
        width: 100%;
        box-sizing: border-box;
        bottom: 0;
      }

      span.is_nav_active {
        padding: 8px 0px;
        color: #fff !important;
        background: transparent !important;
        transform: none !important;
        transition: none !important;
      }
      .is_dom_nav_active {
        transition: all ease-out 0.3s;
        border-bottom: 1px solid #1cc3ff;
      }
      .is_active_border {
        border-bottom: 1px solid #fff;
      }
    }
  }

  .banner {
    position: relative;
    top: -56px;
    width: 100%;
    height: 560px;
    // background-color: #050b24;

    background: linear-gradient(90deg, #1d2f72 0%, #0b1432 100%);
    /deep/.el-carousel {
      .index_content {
        box-sizing: border-box;
        height: 100%;
        // border: 3px solid #0f0;
        margin-top: 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .bannet_item_left {
          width: 550px;
          height: 420px;
          color: #fff;

          font-family: "Microsoft YaHei";
          > div:nth-child(1) {
            margin-top: 50px;
            font-size: 40px;
            letter-spacing: 2px;
            span {
              color: rgba(38, 175, 255, 1);
            }
          }
          > div:nth-child(2) {
            margin-top: 10px;
            width: 80px;
            height: 1px;
            background: #1cc3ff;
          }
          > div:nth-child(3) {
            margin-top: 30px;
            font-size: 16px;
            color: @font_color;
            letter-spacing: 2px;
          }
          > div:nth-child(4) {
            margin-top: 40px;
            width: 200px;
            height: 44px;
            border-radius: 4px;
            background: linear-gradient(90deg, #1cc3ff 0%, #3477ff 100%);
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            position: relative;
            .banner_button_mode {
              position: absolute;
              display: none;
              width: 200px;
              height: 44px;
              border-radius: 4px;
            }
          }
          > div:nth-child(4):hover {
            .banner_button_mode {
              display: block !important;
              background: rgba(23, 43, 61, 0.12);
            }
          }
          > div:nth-child(5) {
            margin-top: 20px;
            font-size: 16px;
            color: #26afff;
          }
        }
        .bannet_item_right {
          // border: 1px solid #fff;
          width: 590px;
          height: 423px;
        }
      }
      .el-carousel__container {
        > div:nth-child(1) {
          .bannet_item_left {
            margin-top: 100px !important;
          }
        }
      }
      .el-carousel__indicators {
        li {
          padding: 12px 6px;
          .el-carousel__button {
            width: 30px;
            height: 3px;
          }
        }
      }
    }
    .banner_0 {
      background: url("../assets/imgs/banner_1.png") center no-repeat;
      div.bannet_item_left {
        margin-left: 40px;
        > div:nth-child(1) {
          margin-top: 100px;
          width: 312px;
          height: 63px;
          background: url("../assets/imgs/official_title.png") no-repeat 100% 100%;
        }
        > div:nth-child(2) {
          margin-top: 10px !important;
          width: 120px !important;
        }
        // display: flex;
        // flex: content;
        // flex-direction: column;
        // align-items: center;
        // justify-content: center;
      }
    }
    .banner_1 {
      background: url("../assets/imgs/banner_2.png") center no-repeat;
    }
    .banner_2 {
      background: url("../assets/imgs/banner_3.png") center no-repeat;
    }
  }

  #scene {
    .scene_banner {
      // height: 560px;
      background-color: #f7f9fa;
      > div.index_content {
        padding-top: 24px;
        > div:nth-child(1) {
          text-align: center;
          font-size: 28px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 41px;
          color: #525866;
        }
        > div:nth-child(2) {
          text-align: center;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 41px;
          color: @font_color;
        }
        > div:nth-child(3) {
          margin-top: 60px;
          height: 484px;
          background: #ffffff;
          display: flex;
          overflow: hidden;
          cursor: pointer;
          div.scene_banner_conent_active {
            width: 600px;
            background: #fff !important;
            position: relative;
            div.scene_banner_item {
              display: flex;
              flex-direction: column;
              // justify-content: space-between;
              // align-items: center;
              // display: none;
              transition: height 0.8s;
              background: #fff !important;
              height: 84px;
              width: 100%;
              color: #525866;
              padding: 0;
              font-size: 18px;
              .span_width {
                // transition: border 0.8s;
                // border: 1px solid #f60;
                border-top: none;
                border-left: none;
                border-right: none;
                width: 100%;
                text-align: center;
                line-height: 84px;
                height: 100%;
              }
            }
            div.scene_banner_item_bg {
              transition: width 0.8s;
              position: absolute;
              bottom: 0;
              height: 2px;
              width: 100%;
              // background: #04be02;
            }
          }
          .scene_banner_conent {
            position: relative;
            width: 200px;
            box-sizing: border-box;
            transition: all 0.8s;
            display: flex;
            align-items: center;
            .scene_banner_item_bg {
              position: absolute;
              bottom: 0;
              left: 0;
              width: 0px;
              height: 2px;
            }
            .scene_banner_item {
              background-color: rgba(33, 38, 86, 0.6);
              box-sizing: border-box;
              position: absolute;
              padding: 0 30px;
              bottom: 0;
              word-wrap: break-word;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              font-size: 24px;
              font-family: Microsoft YaHei;
              font-weight: bold;
              width: 200px;
              margin: 0 auto;
              line-height: 42px;
              height: 100%;
              color: #fff;
              transition: all 0.8s;
              span.span_title_cype {
                width: 20px;
              }
            }
            img {
              width: 600px;
            }
            /*  */
          }
          .scene_banner_conent:nth-child(1) {
            .scene_banner_item {
              background: rgba(27, 75, 60, 0.6);
            }
          }
          .scene_banner_conent:nth-child(2) {
            .scene_banner_item {
              background: rgba(0, 43, 86, 0.6);
            }
          }
          .scene_banner_conent:nth-child(3) {
            .scene_banner_item {
              background: rgba(71, 25, 32, 0.6);
            }
          }
          .scene_banner_conent:nth-child(4) {
            .scene_banner_item {
              background: rgba(56, 50, 45, 0.6);
            }
          }
        }
      }
    }
    .scene_advantage {
      margin-top: 60px;
      height: 600px;
      background: url("../assets/imgs/scene_advantage.png") 100% 100% no-repeat;
      > div.index_content {
        padding-top: 80px;

        > div:nth-child(1) {
          text-align: center;
          font-size: 28px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 41px;
          color: #fff;
          // letter-spacing: 80px;
        }
        > div:nth-child(2) {
          text-align: center;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 41px;
          color: @font_color;
          // letter-spacing: 80px;
        }
        > div:nth-child(3) {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          // background: #ffffff;
          box-shadow: 0px 3px 20px rgba(6, 18, 42, 0.08);
          > div.scene_advantage_item {
            margin-top: 60px;
            width: 580px;
            height: 128px;
            background: #06122a;
            border: 1px solid #354857;
            border-radius: 12px;
            padding: 24px 30px;
            box-sizing: border-box;
            > div {
              font-size: 20px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #ffffff;
              display: flex;
              div:nth-child(1) {
                width: 34px;
                height: 28px;
                margin-right: 20px;
                img {
                  width: 100%;
                }
              }
            }
            > div:nth-child(2) {
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              line-height: 21px;
              color: #cccccc;
              > div:nth-child(2) {
                margin-top: 22px;
              }
            }
          }
          > div.scene_advantage_item:hover {
            border-color: #26afff;
            > div:nth-child(1) {
              div:nth-child(2) {
                color: #26afff;
              }
            }
          }
        }
      }
    }
  }
  #introduce {
    // height: 600px;
    .introduce_constitute {
      // height: 886px;
      background-color: #f7f9fa;
      div.index_content {
        padding-top: 80px;
        > div:nth-child(1) {
          text-align: center;
          font-size: 28px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 41px;
          color: #525866;
        }
        > div:nth-child(2) {
          text-align: center;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 41px;
          color: @font_color;
        }
        > div:nth-child(3) {
          margin-top: 60px;
          height: 560px;
          display: flex;
          justify-content: flex-end;
          .introduce_constiute_time_right {
            // overflow: hidden;
            width: 880px;
            height: 560px;
            // background: #051b4a;
            position: relative;
            display: flex;
            .introduce_constiute_time_right_content {
              flex: 1;
              margin: 115px 0 0 242px;
              // background: chartreuse;
              overflow: hidden;
              > div:nth-child(1) {
                .title_item {
                  position: relative;
                  width: 640px;
                  height: 150px;
                  overflow: hidden;
                  > div {
                    position: absolute;
                    top: 0;
                    left: 0;
                    opacity: 0;
                    // background: #051b4a;
                    > div:nth-child(1) {
                      span {
                        color: #fff;
                        font-size: 28px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                      }
                    }
                    > div:nth-child(2) {
                      margin-top: 5px;
                      width: 60px;
                      height: 2px;
                      background: linear-gradient(
                        90deg,
                        #26afff 0%,
                        rgba(38, 175, 255, 0) 100%
                      );
                      opacity: 1;
                    }
                    > div:nth-child(3) {
                      margin-top: 50px;
                      width: 400px;
                      font-size: 14px;
                      font-family: Microsoft YaHei;
                      font-weight: 400;
                      line-height: 28px;
                      color: #ffffff;
                    }
                  }
                }
                .title_item_active {
                  transition: opacity 0.5s linear;
                  opacity: 1 !important;
                }
              }
              > div:nth-last-child(1) {
                margin-top: 100px;
                display: flex;
                justify-content: space-between;
                overflow: hidden;
                white-space: nowrap;
                > div {
                  width: 200px;
                  height: 150px;
                  position: relative;
                  // opacity: 0.8;
                  cursor: pointer;
                  img {
                    width: 100%;
                  }
                  > div:nth-child(2) {
                    position: absolute;
                    z-index: 10;
                    top: 0;
                    left: 0;
                    width: 200px;
                    height: 150px;
                    line-height: 150px;
                    text-align: center;
                    opacity: 0.8;
                    // background: #31446e;
                  }
                  > div:nth-child(3) {
                    position: absolute;
                    z-index: 20;
                    top: 0;
                    left: 0;
                    width: 200px;
                    height: 150px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 28px;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    // color: #ffffff;
                    color: rgba(255, 255, 255, 0.8);
                    // opacity: 0.8;
                  }
                }
                .options_active {
                  // transition: opacity 0.5s linear;
                  // opacity: 0.5 !important;
                  > div:nth-child(2) {
                    opacity: 0.3 !important;
                  }
                  > div:nth-child(3) {
                    color: rgba(255, 255, 255, 0.3) !important;
                    // opacity: 0.2 !important;
                  }
                }
                /* > div {
                  // border: 1px solid #fff;
                  width: 200px;
                  height: 150px;
                  background: #051b4a;
                  cursor: pointer;
                  position: relative;
                  background-size: 100% 100% !important;
                  opacity: 0.8;
                  > div {
                    width: 200px;
                    height: 150px;
                    position: absolute;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 28px;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    line-height: 41px;
                    color: #ffffff;
                  }
                } */
              }
            }
            .introduce_constiute_time_left_top {
              // border: 1px solid #000;
              position: absolute;
              left: -320px;
              top: 78px;
              width: 480px;
              height: 450px;
              // border: 1px solid #0f0;
              overflow: hidden;
              .introduce_constitute_active {
                transition: opacity 0.5s linear;
                opacity: 1 !important;
              }
              .introduce_constiute_time_left_top_img {
                // border: 1px solid #0f0;
                background-color: #031b52;
                width: 100%;
                height: 360px;
                position: relative;
                opacity: 1;
                img {
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 480px;
                  height: 360px;
                  // display: none;
                  opacity: 0;
                }
              }
              .introduce_constiute_time_left_top_button {
                margin-top: 40px;
                display: flex;
                > div {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  cursor: pointer;
                }

                /deep/.el-button {
                  overflow: hidden;
                  position: relative;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  border: none;
                  outline: none;
                  margin-left: 80px;
                  width: 48px;
                  height: 48px;
                  background: rgba(239, 240, 242);
                  opacity: 0.4;
                  span {
                    display: block !important;
                    width: 48px;
                    height: 48px;
                    line-height: 48px;
                    i {
                      display: inline-block;
                      font-size: 24px;
                      width: 48px;
                      height: 48px;
                      line-height: 48px;
                    }
                    i::before {
                      display: inline-block;
                      font-size: 24px;
                      width: 48px;
                      height: 48px;
                      line-height: 48px;
                    }
                  }
                }
                .button_left {
                  overflow: hidden;
                  position: relative;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  border: none;
                  outline: none;
                  margin-left: 80px;
                  width: 48px;
                  height: 48px;
                  background: rgba(239, 240, 242, 1);
                }
                .button_right {
                  overflow: hidden;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  border: none;
                  outline: none;
                  margin-left: 60px;
                  width: 48px;
                  height: 48px;
                  background: rgba(239, 240, 242, 1);
                  span {
                    display: block;
                    width: 100%;
                    height: 100%;
                  }
                  i {
                    font-size: 24px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  #download {
    margin-top: 120px;
    .download_bg {
      height: 600px;
      background: url("../assets/imgs/download_bg.png") center no-repeat;
      > div.index_content {
        padding-top: 80px;
        > div:nth-child(1) {
          text-align: center;
          font-size: 28px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 41px;
          color: #fff;
          // letter-spacing: 80px;
        }
        > div:nth-child(2) {
          text-align: center;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 41px;
          color: @font_color;
          // letter-spacing: 80px;
        }
        > div:nth-child(3) {
          margin-top: 60px;
          width: 640px;
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          .download_bg_item {
            margin-top: 50px;
            > div:nth-child(1) {
              width: 290px;
              height: 72px;
              border: 1px solid #8090a5;
              border-radius: 4px;
              display: flex;
              padding: 18px 0px 18px 30px;
              box-sizing: border-box;
              align-items: center;
              position: relative;

              > div:nth-child(1) {
                width: 36px;
                height: 36px;
                // border: 1px solid #000;
                /* img {
                  width: 100%;
                } */
                i {
                  font-size: 36px;
                }
              }
              > div:nth-child(2) {
                margin: 0 12px 0 30px;
              }
              > div {
                color: #8090a5;
                font-size: 20px;
              }
            }
            .download_bg_item_title {
              margin-top: 10px;
              font-size: 12px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #cccccc !important;
              height: 15px;
              span {
                display: none;
              }
            }
          }
          .download_bg_item_active {
            cursor: pointer;
            position: relative;
            .download_mode {
              position: absolute;
              width: 290px;
              height: 72px;
              display: none;
              top: 0;
              left: 0;
            }
            > div:nth-child(1) {
              background: linear-gradient(90deg, #26afff 0%, #3477ff 100%);
              border: none !important;
              div {
                color: #fff;
              }
            }
            .download_bg_item_title {
              span {
                display: block;
              }
            }
          }
          .download_bg_item_active:hover {
            .download_mode {
              background: rgba(23, 43, 61, 0.12);
              display: block !important;
            }
          }
        }
      }
    }
    .download_step {
      height: 406px;
      background-color: #fff;
      > div.index_content {
        padding-top: 80px;
        > div:nth-child(1) {
          text-align: center;
          font-size: 28px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 41px;
          color: #525866;
        }
        > div:nth-child(2) {
          text-align: center;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 41px;
          color: @font_color;
          // letter-spacing: 80px;
        }
        > div:nth-child(3) {
          margin-top: 60px;
          display: flex;
          align-items: center;
          > div {
            width: 360px;
            height: 120px;
            background: #fff;
            border: 1px solid #f5f5f5;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            position: relative;
            > div:nth-child(1) {
              position: absolute;
              top: 30px;
              z-index: 13;
              font-size: 20px;
              font-weight: 400;
              color: #333333;
            }
            > div:nth-child(2) {
              position: absolute;
              top: 70px;
              z-index: 13;
              font-weight: bold;
              font-size: 24px;
              color: #959fa7;
            }
            > div:nth-child(3) {
              height: 0px;
              width: 100%;
              position: absolute;
              top: 0;
              background: #f7f9fa;
              transition: all 0.6s;
            }
          }
          > div:hover {
            cursor: pointer;
            > div:nth-child(3) {
              // transition: all 1s;
              height: 120px;
            }
            > div:nth-child(2) {
              color: #26afff;
            }
          }

          > img {
            width: 24px;
            margin: 0 18px;
          }
        }
      }
    }
  }
  #about {
    background-color: #f7f9fa;
    .about_advantage {
      // height: 552px;
      > div.index_content {
        padding-top: 80px;
        > div:nth-child(1) {
          text-align: center;
          font-size: 28px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 41px;
          color: #525866;
          // letter-spacing: 80px;
        }
        > div:nth-child(2) {
          margin-top: 90px;
          display: flex;
          justify-content: space-between;
          > div {
            position: relative;
            width: 282px;
            height: 265px;
            background: #ffffff;
            box-shadow: 0px 3px 20px rgba(6, 18, 42, 0.08);
            text-align: center;
            cursor: pointer;
            border-radius: 5px;
            > div:nth-child(1) {
              position: absolute;
              top: -30px;
              left: 82.5px;
              width: 100px;
              height: 100px;
              // background: linear-gradient(180deg, #1cc3ff 0%, #3477ff 100%);
              opacity: 1;
              border-radius: 0px;
              img {
                width: 100%;
              }
            }
            > div:nth-child(2) {
              margin-top: 120px;
              color: #525866;
              font-size: 16px;
              font-family: Microsoft YaHei;
              font-weight: bold;
            }
            > div:nth-child(3) {
              font-size: 14px;
              margin-top: 18px;
              // width: 238px;
              text-align: center;
              display: flex;
              justify-content: center;
              color: @font_color;
              div {
                width: 170px;
              }
            }
            > div:nth-last-child(1) {
              position: absolute;
              bottom: -30px;
              height: 0px;
              width: 100%;
              // display: none;
              background: url("../assets/imgs/about_bg.png") no-repeat;
              background-size: 100% 100%;
            }
          }
          > div:hover {
            // position: relative;
            // top: -30px;
            transform: translateY(-30px);
            // background: linear-gradient(180deg, #1cc3ff 0%, #3477ff 100%);
            box-shadow: 0 0 3px 3px #c1ecfc !important;
            transition: transform 0.6s linear;
            div:nth-last-child(1) {
              height: 24px;
            }
          }
        }
      }
    }
    .about_join {
      margin-top: 80px;
      height: 320px;
      > div.index_content {
        background-color: #233864;
        box-sizing: border-box;
        padding: 60px 60px 40px 60px;
        position: relative;
        > div:nth-child(1) {
          text-align: center;
          font-size: 28px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 41px;
          color: #fff;
        }
        > div:nth-child(2) {
          margin-top: 50px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          > div {
            font-size: 16px;
            display: flex;
            align-items: center;
            color: #fff;
            > div {
              width: 50px;
              height: 42px;
              margin-right: 8px;
              img {
                width: 100%;
              }
            }
            > img {
              width: 24px;
            }
          }
        }
        > div:nth-child(3) {
          margin-top: 40px;
          > div:nth-child(1) {
            margin: 0 auto;
            width: 200px;
            height: 44px;
            border-radius: 4px;
            font-size: 18px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            cursor: pointer;
            background: linear-gradient(90deg, #1cc3ff 0%, #3477ff 100%);
            position: relative;
            .an_fa {
              display: none;
              position: absolute;
              top: -36px;
              right: 0;
              width: 46px;
              height: 40px;
            }
            .animate_black {
              display: block;
            }
            .button_mode {
              position: absolute;
              width: 200px;
              height: 44px;
              border-radius: 4px;
              display: none;
            }
          }
          > div:nth-child(1):hover {
            .button_mode {
              background: rgba(23, 43, 61, 0.12);
              display: block !important;
            }
          }
        }
        > div:nth-last-child(1) {
          position: absolute;
          z-index: 1;
          bottom: 0px;
          right: 0px;
          width: 226px;
          height: 260px;
          background: url("../assets/imgs/about_bottom_bg.png") no-repeat;
          background-size: 100% 100%;
        }
      }
    }
  }
  .footer {
    margin-top: 120px;
    .footer_top {
      background: #081e38;
      .index_content {
        display: flex;
        box-sizing: border-box;
        padding-top: 40px;
        padding-bottom: 60px;
        // height: 393px;
        justify-content: space-between;
        > div {
          width: 480px;
          .footer_top_left_spacing {
            letter-spacing: 2px;
            word-spacing: 0px;
          }
          > div {
            font-size: 14px;
            font-family: Microsoft YaHei;
            color: @font_color;
            margin-top: 20px;
          }
          > div:nth-child(1) {
            font-size: 16px;
            color: #fff;
            padding-bottom: 40px;
            border-bottom: 1px solid rgba(255, 255, 255, 0.08);
          }
        }
        .footer_top_content {
          width: 240px;
          span {
            cursor: pointer;
          }
          > div:nth-child(2) {
            span {
              margin-right: 20px;
            }
          }
          > div:nth-child(3) {
            color: #fff;
            font-size: 16px;
          }
        }
        .footer_top_right {
          width: 240px;
          i {
            cursor: pointer;
            color: #ffffff;
            font-size: 26px;
            width: 44px;
            height: 44px;
            display: inline-block;
            border-radius: 50%;
            background: #354857;
            line-height: 44px;
            text-align: center;
            margin-right: 40px;
            position: relative;
            div {
              width: 192px;
              height: 216px;
              background: #ffffff;
              position: absolute;
              top: -242px;
              left: 0;
              display: none;
            }
          }
          i.icon-shejiaotubiao-03:hover {
            background: #3970e3;
            transform: scale(1.1);
            > div {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              padding-bottom: 10px;
              span {
                font-size: 12px;
                color: #333333;
                line-height: 30px;
              }
              img {
                width: 160px;
                height: 160px;
              }
            }
          }
          i.icon-gongzhonghao:hover {
            background: #04be02;
            transform: scale(1.1);
            > div {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              padding-bottom: 10px;
              span {
                font-size: 12px;
                color: #333333;
              }
              img {
                width: 160px;
                height: 160px;
              }
            }
          }
          > div:nth-child(3) {
            color: #fff;
            font-size: 16px;
          }
          > div:nth-last-child(1) {
            font-size: 20px;
            color: #fff;
          }
        }
      }
    }
    .footer_bottom {
      height: 80px;
      // background: rgba(0, 0, 0, 0.88);
      background: #071a31;
      > div {
        font-size: 14px;
        display: flex;
        align-items: center;
        height: 100%;
        color: @font_color;
        cursor: pointer;
      }
      img{
        height: 16px;
      }
    }
  }
}
.el-backtop {
  width: 48px;
  height: 48px;
  border-radius: 4px;
  background: #fff !important;
  .scrollTop {
    // border-radius: 50%;
    /* i {
      font-size: 20px;
      color: @font_color;
    } */
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    // border: 1px solid #000;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
    transition: all ease-out 0.6s;
    i {
      font-size: 24px;
      cursor: pointer;
      position: relative;
      transition: all ease-out 0.6s;
      display: inline-block;
      width: 100%;
      height: 100%;
      line-height: 44px;
      text-align: center;
    }
    i::before {
      width: 100%;
      height: 100%;
      line-height: 44px;
      font-size: 24px;
      position: absolute;
      content: "\e90a";
      left: 0px;
      top: 2px;
      color: rgb(53, 72, 87);
      transform: translateY(100%);
    }
    i::after {
      width: 100%;
      height: 100%;
      line-height: 44px;
      font-size: 24px;
      position: absolute;
      content: "\e90a";
      left: 0px;
      top: 2px;
      color: #354857;
      transform: translateY(0%);
    }
    i:hover {
      transition: all ease-out 0.6s;
      transform: translateY(-100%);
    }
  }
}
.el-backtop:hover {
  i {
    color: #354857 !important;
  }
}

.button_right_test {
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #000;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  transition: all 0.6s linear;
  border-radius: 4px;
  i {
    font-size: 24px;
    cursor: pointer;
    position: relative;
    transition: all 0.6s linear;
    display: inline-block;
    width: 100%;
    height: 100%;
    line-height: 46px;
    text-align: center;
  }
  i::before {
    width: 100%;
    height: 100%;
    line-height: 44px;
    font-size: 24px;
    position: absolute;
    content: "\e909";
    left: 48px;
    top: 2px;
    color: rgb(53, 72, 87);
    transform: translateX(-100%);
  }
  i::after {
    width: 100%;
    height: 100%;
    line-height: 44px;
    font-size: 24px;
    position: absolute;
    content: "\e909";
    left: 0px;
    top: 2px;
    color: #354857;
    transform: translateX(-100%);
  }
  i:hover {
    transition: all ease-out 0.6s;
    transform: translateX(100%);
  }
  /* i:hover {
    position: absolute;
    content: "\e909";
    left: 46px;
    top: 5px;
    color: #f60;
    transform: translateX(-100%);
  } */
}
.button_left_test {
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  // border: 1px solid #000;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  transition: all ease-out 0.6s;
  border-radius: 4px;
  i {
    font-size: 24px;
    cursor: pointer;
    position: relative;
    transition: all ease-out 0.6s;
    display: inline-block;
    width: 100%;
    height: 100%;
    line-height: 44px;
    text-align: center;
  }
  i::before {
    width: 100%;
    height: 100%;
    line-height: 44px;
    font-size: 24px;
    position: absolute;
    content: "\e60e";
    left: 48px;
    top: 2px;
    color: rgb(53, 72, 87);
    transform: translateX(-100%);
  }
  i::after {
    width: 100%;
    height: 100%;
    line-height: 44px;
    font-size: 24px;
    position: absolute;
    content: "\e60e";
    left: 0px;
    top: 2px;
    color: #354857;
    transform: translateX(100%);
  }
  i:hover {
    transition: all ease-out 0.6s;
    transform: translateX(-100%);
  }
  /* i:hover {
    position: absolute;
    content: "\e909";
    left: 46px;
    top: 5px;
    color: #f60;
    transform: translateX(-100%);
  } */
}
.nav_active_bg {
  background: rgb(5, 27, 74);
  transition: ease-out all 0.6s;
}
</style>
