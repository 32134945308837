import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    dia : false,//是否开启退出应用
    from_url:'',
    focus_blur:'',//首页焦点
    tokenStatus:true,//token状态
  },
  mutations: {
    updateTokenStatus (state, bool) {
      state.tokenStatus = bool
      console.log('=1=2=',state,bool);
    }
  },
  actions: {
  },
  modules: {
  }
})
